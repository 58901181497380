import "core-js/stable";
import "regenerator-runtime/runtime";

// disable until full turboing
import { Turbo } from "@hotwired/turbo-rails";
Turbo.session.drive = false;
global.jQuery = require('jquery');
global.$ = require('jquery');

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("controllers");

require('bootstrap');
require('vendor/waypoints.min.js');
require('vendor/jquery.counterup.min.js');
require('vendor/jquery.easing.min.js');

const images = require.context('../images/homepage', true);
const imagePath = (name) => images(name, true);

import 'stylesheets/font-awesome';
import 'stylesheets/homepage';

document.addEventListener("turbo:before-cache", function() {
  $('[data-no-cache]').remove();
});

/*global $, window, document, setTimeout, WOW, jQuery*/
$(document).on('ready turbo:load', function() {
  'use strict';
  // Defining used variables
  var skill            =   $('.skill'),
      accordionBox     =   $('.accordion-box'),
      accordion        =   $('.accordion'),
      accordionContent =   $('.acc-content');


  // Smooth scrolling using jQuery easing
  $('a.js-scroll-trigger[href*="#"]:not([href="#"])').on('click', function() {
      if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          $('html, body').animate({
            scrollTop: (target.offset().top - 48)
          }, 1000, "easeInOutExpo");
          return false;
          }
      }
  });

  //Fact Counter + Text Count
  $('.counter').counterUp({
      delay: 10,
      time: 3000
  });

  //Accordion Box
  if(accordionBox.length){
      $(this).on('click', '.acc-btn', function() {
          var outerBox = $(this).parents(accordionBox);
          var target = $(this).parents(accordion);
          if($(this).hasClass('active')!==true){
              $('.accordion .acc-btn').removeClass('active');
          }
          if ($(this).next(accordionContent).is(':visible')){
              return false;
          }else{
              $(this).addClass('active');
              $(outerBox).children(accordion).removeClass('active-block');
              $(outerBox).find(accordion).children('.acc-content').slideUp(300);
              target.addClass('active-block');
              $(this).next(accordionContent).slideDown(300);
          }
      });
  }

  $("body").css("overflow","auto");
  $(".preloader").fadeOut(1000,function(){
      $(this).remove();
  });
  $("#nav-collapser").click(function() {
    $("#navbar").toggleClass("collapse")
  })
});
