import { Controller } from "@hotwired/stimulus"
import  CryptoJS from "crypto-js"

export default class extends Controller {
  static targets = [ "display", "method" ]

  ascToHex(asc) {
    var hex = '';
    for (var i = 0; i < asc.length; i++) {
          hex += ('0' + asc.charCodeAt(i).toString(16)).slice(-2);
        }
    return hex;
  }

  setIframe() {
    var params = {
      "developer-id": this.developerId,
      "hash-key": this.hashKey,
      "user-id": this.userId,
      "timestamp": Math.floor((Math.floor(Date.now()) - (1*60000))/1000),
      "data": {
        "accountvault": {
          "payment_method": this.methodTarget.value,
          "location_id": this.locationId,
          "stylesheet_url": "https://accuage-cdn.s3.amazonaws.com/paya.css",
          "account_vault_api_id": [...Array(10)].map(i=>(~~(Math.random()*36)).toString(36)).join(''),
          "title": "Payment Name",
          "account_holder_name": this.merchantName,
          "show_account_holder_name": true,
          "show_street": true,
          "ach_sec_code": "WEB"
        }
      }
    };
    params['hash-key'] = CryptoJS.HmacSHA256(params['user-id'] + params['timestamp'], this.hashKey);
    params['data'] = this.ascToHex(JSON.stringify(params['data']));
    var url = this.payaHost + "/v2/accountform?";
    Object.keys(params).forEach(function (key) {
      url += key + '=' + params[key] + '&';
    });
    this.displayTarget.src = url;
  }

  connect() {
    this.developerId = document.querySelector('meta[name="developerId"]').getAttribute('content');
    this.hashKey = document.querySelector('meta[name="hashKey"]').getAttribute('content');
    this.userId = document.querySelector('meta[name="userId"]').getAttribute('content');
    this.locationId = document.querySelector('meta[name="locationId"]').getAttribute('content');
    this.payaHost = document.querySelector('meta[name="phost"]').getAttribute('content');
    this.accountVaultApiId = document.querySelector('meta[name="accountVault"]').getAttribute('content');
    this.merchantName = document.querySelector('meta[name="username"]').getAttribute('content');
    window.addEventListener("message", receiveMessage, false);

    function receiveMessage(event) {
      var allowed = document.querySelector('meta[name="phost"]').getAttribute('content');
      var paymentData =""
      if (event.origin !== allowed) return;
        paymentData = JSON.parse(event.data);
        if (paymentData.payment_method == 'cc') {
          document.getElementById('payment_method_external_payment_method_id').value = paymentData.id;
          document.getElementById('payment_method_external_payment_method_type').value = paymentData.payment_method;
          document.getElementById('payment_method_identifier').value = paymentData.last_four;
          document.getElementById('payment_method_expires').value = paymentData.exp_date;
          document.getElementById('payment_method_brand').value = paymentData.account_type;
        } else {
          document.getElementById('payment_method_external_payment_method_id').value = paymentData.id;
          document.getElementById('payment_method_external_payment_method_type').value = paymentData.payment_method;
          document.getElementById('payment_method_identifier').value = paymentData.last_four;
          document.getElementById('payment_method_expires').value = paymentData.exp_date;
          document.getElementById('payment_method_brand').value = paymentData.account_type;
        }
        document.getElementById('payment-method-form').submit();
      }
    this.setIframe();
  }

  processPaymentMethod(paymentData) {

  }
}
