import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  toggleModal(event) {
    event.preventDefault();
    $("#eager-checkout-modal").modal('toggle');
  }

}
