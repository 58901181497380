import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["customerId"];

  lookup(event) {
    event.preventDefault();
    Turbo.visit(`/admin/customers/${this.customerIdTarget.value}`);
  }
}
